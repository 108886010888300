<template>
 <main class="relative h-screen">
    <div class="h-80 lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
      <img src="https://firebasestorage.googleapis.com/v0/b/jan-art.appspot.com/o/assets%2Fpexels-ilya-shakir-2440471%20(1).jpg?alt=media&token=a49f003e-6f0e-4c77-97ed-3414b218211c" alt="TODO" class="h-full w-full object-cover object-center" />
    </div>

    <div class="h-full lg:overflow-y-scroll">
      <div class="mx-auto max-w-2xl px-4 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 xl:gap-x-24 py-6">
        <div class="lg:col-start-2">
          <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 md:text-3xl">{{translatedData.header}}</p>
          <p class="mt-2 text-base text-gray-500">{{translatedData.headerDescription}}</p>

          <dl class="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-4 text-sm text-gray-600">
            <div>
              <dl v-auto-animate class="text-sm font-medium flex sm:flex-col gap-1">
                <div class="flex-1">
                  <dt class="text-gray-900">{{translatedData.orderNumber}}</dt>
                  <dd v-tooltip="`${order.systemId}`" v-if="!loading" class="mt-1 text-indigo-600">{{order.id}}</dd>
                  <SkeletonTheme v-if="loading" color="#e8eaed" class="mt-1">
                    <Skeleton height="1rem" width="8rem" />
                  </SkeletonTheme>
                </div>
                <div class="flex-1">
                  <dt class="text-gray-900">{{translatedData.deliveryDate}}</dt>
                  <dd v-if="!loading" class="mt-1 text-indigo-600 flex gap-1 items-center">
                    {{order.deliveryDate}}
                    <!-- <div :class="order.dateConfirmed ? 'bg-green-50 text-green-500' : 'bg-red-50 text-red-500'" class="rounded-md px-2.5 py-0.5 bg-gray-100 text-gray-800 text-xs font-medium">{{order.dateConfirmed ? translatedData.deliveryConfirmed : translatedData.deliveryNotConfirmed}}</div> -->
                  </dd>
                  <SkeletonTheme v-if="loading" color="#e8eaed" class="mt-1">
                    <Skeleton height="1rem" width="8rem" />
                  </SkeletonTheme>
                </div>
                <div class="hidden sm:block flex-1">
                  <dt class="text-gray-900">{{translatedData.plannedDeliveryHours}}</dt>
                  <dd v-if="!loading" class="mt-1 text-indigo-600 flex gap-1 items-center">
                    {{order.plannedDeliveryHours != null ? order.plannedDeliveryHours : '---'}}
                  </dd>
                  <SkeletonTheme v-if="loading" color="#e8eaed" class="mt-1">
                    <Skeleton height="1rem" width="8rem" />
                  </SkeletonTheme>
                </div>
              </dl>
            </div>
            <div class="block sm:hidden flex-1">
              <dt class="text-gray-900">{{translatedData.plannedDeliveryHours}}</dt>
              <dd v-if="!loading" class="mt-1 text-indigo-600 flex gap-1 items-center">
                {{order.plannedDeliveryHours}}
              </dd>
              <SkeletonTheme v-if="loading" color="#e8eaed" class="mt-1">
                <Skeleton height="1rem" width="8rem" />
              </SkeletonTheme>
            </div>
            <div v-auto-animate>
              <dt class="font-medium text-gray-900">{{translatedData.deliveryAddress}}</dt>
              <dd v-if="!loading" class="mt-1">
                <address class="not-italic">
                  <span class="block">{{order.name}}</span>
                  <span class="block">{{order.address}}</span>
                  <span class="block">{{order.postcode.length === 0 ? order.city : order.city+', '+order.postcode}}</span>
                </address>
              </dd>
              <SkeletonTheme v-if="loading" color="#e8eaed" class="mt-1">
                <Skeleton height="4rem" />
              </SkeletonTheme>
            </div>
          </dl>
          <div class="w-full flex mt-4 justify-start">
            <div class="w-full flex-col">
              <button v-auto-animate v-if="!loading" :disabled="order.dateConfirmed" @click="deliveryConfirmation" type="button" :class="['w-full text-sm justify-center transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600', order.dateConfirmed && !confirmingDelivery ? 'text-green-600 bg-green-100 cursor-not-allowed' : 'text-indigo-600 hover:bg-indigo-200 bg-indigo-100']">
                  <div v-if="!order.dateConfirmed && !confirmingDelivery" class="flex gap-x-1.5 whitespace-nowrap">
                    <TruckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    {{translatedData.deliveryNotConfirmed}}
                  </div>
                  <div v-if="confirmingDelivery" class="spinner flex whitespace-nowrap"></div>
                    <div v-if="order.dateConfirmed && !confirmingDelivery" class="flex gap-x-1.5 whitespace-nowrap">
                      <CheckIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      {{translatedData.deliveryConfirmed}}
                    </div>
              </button>
              <div v-auto-animate v-if="!loading">
                <div v-if="order.dateConfirmed && !confirmingDelivery" class="select-none flex justify-center mt-4 whitespace-nowrap">
                  <span class="font-semibold uppercase text-gray-600">{{translatedData.deliveryCode}}: <span class="text-indigo-500 font-bold">{{this.order.code}}</span> </span>
                </div>
              </div>
            </div>
            <div v-if="loading" class="w-full">
              <SkeletonTheme color="#e8eaed">
                <Skeleton height="2.25rem" />
              </SkeletonTheme>
            </div>
          </div>
          <ul v-auto-animate role="list" class="mt-4 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
            <li v-if="order !== null && !loading" v-for="(product, index) in order.products" :key="index" class="flex flex-col sm:flex-row sm:justify-between gap-4 py-6">
              <div class="flex gap-4">
                <div class="w-24">
                  <div v-if="product.image.length > 0" class="bg-gray-100 h-24 w-24 rounded-md flex justify-center items-center">
                    <img :src="product.image" :alt="product.imageAlt" class="h-20 w-20 flex-none rounded-md bg-gray-100 object-cover object-center" />
                  </div>
                  <div v-if="product.image.length === 0" class="h-24 w-24 flex items-center justify-center rounded-md bg-gray-100">
                    <PhotographIcon class="h-12 w-12 text-gray-300" />
                  </div>
                </div>
                <p class="hidden sm:block text-gray-900">
                    {{ product.name.length > 150 ? product.name.slice(0, 150)+'...' : product.name }}
                </p>
                <p class="sm:hidden text-gray-900">
                    {{ product.name.length > 110 ? product.name.slice(0, 110)+'...' : product.name }}
                </p>
              </div>

              <div class="flex flex-row-reverse sm:flex-col justify-between whitespace-nowrap">
                <p class="flex-none font-medium text-gray-900">{{product.price*product.quantity}} {{order.currency}}</p>
                <p class="text-right">{{ product.quantity }} szt.</p>
              </div>
            </li>
            <li v-if="loading" class="flex space-x-6 py-6">
              <SkeletonTheme color="#e8eaed">
                <Skeleton height="6rem" width="6rem" />
              </SkeletonTheme>
              <div class="flex-auto space-y-1">
                <h3 class="text-gray-900">
                  <SkeletonTheme color="#e8eaed">
                    <Skeleton height="1rem" width="14rem" />
                  </SkeletonTheme>
                </h3>
                <!-- <p>{{ product.color }}</p> -->
                <p>                  
                  <SkeletonTheme color="#e8eaed">
                    <Skeleton height="1rem" width="2rem" />
                  </SkeletonTheme>
                </p>
              </div>
              <p class="flex-none font-medium text-gray-900">
                <SkeletonTheme color="#e8eaed">
                  <Skeleton height="1rem" width="3rem" />
                </SkeletonTheme>
              </p>
            </li>
          </ul>

          <dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
            <div v-auto-animate class="flex justify-between">
              <dt>{{translatedData.totalProductsPrice}}</dt>
              <dd v-if="!loading" class="text-gray-900">{{order.totalProductPrice}} {{order.currency}}</dd>
              <SkeletonTheme v-if="loading" color="#e8eaed">
                <Skeleton height="1rem" width="3rem" />
              </SkeletonTheme>
            </div>

            <div v-auto-animate class="flex justify-between">
              <dt>{{translatedData.deliveryPrice}}</dt>
              <dd v-if="!loading" class="text-gray-900">{{this.isReturn ? 0 : order.deliveryPrice}} {{order.currency}}</dd>
              <SkeletonTheme v-if="loading" color="#e8eaed">
                <Skeleton height="1rem" width="3rem" />
              </SkeletonTheme>
            </div>

            <div v-auto-animate class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt class="text-base">{{translatedData.total}}</dt>
              <dd v-if="!loading" class="text-base">{{order.totalProductPrice+(this.isReturn ? 0 : order.deliveryPrice)}} {{order.currency}}</dd>
              <SkeletonTheme v-if="loading" color="#e8eaed">
                <Skeleton height="1.5rem" width="3rem" />
              </SkeletonTheme>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import { PhotographIcon, TruckIcon, CheckIcon } from '@heroicons/vue/outline'
import { Skeleton, SkeletonTheme } from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"

export default {
  components: {
    Skeleton, SkeletonTheme, PhotographIcon, TruckIcon, CheckIcon
  },  
  data() {
    return {
      order: null,
      loading: true,
      confirmingDelivery: false,
      translatedData: {
        header: "Potwierdzenie dostawy zamówienia",
        headerDescription: "Kliknij w przycisk, aby potwierdzić dostawę zamówienie.",
        orderNumber: "Numer zamówienia",
        deliveryDate: "Planowana data dostawy",
        plannedDeliveryHours: "Planowane godziny dostawy",
        deliveryAddress: "Adres dostawy",
        totalProductsPrice: "Suma za przedmioty",
        deliveryPrice: "Dostawa",
        total: "Suma",
        deliveryConfirmed: "Potwierdzono dostawę",
        deliveryCode: "Kod odbioru",
        deliveryNotConfirmed: "Potwierdź dostawę"
      },
      lang: null,
      isReturn: false,
    }
  },
  mounted() {
    this.downloadOrder()
  },
  created() {
    this.setLanguage();
  },
  methods: {
    checkProductsForKeywords() {
      const keywords = ['ZWROT', 'REKLAMACJA'];
      const hasKeyword = this.order.products.some(product => {
        const name = product.name.toUpperCase();
        return keywords.some(keyword => name.includes(keyword));
      });

      if (hasKeyword) {
        return this.isReturn = true;
      } else {
        return this.isReturn = false;
      }
    },
    async deliveryConfirmation() {
      try {
        this.confirmingDelivery = true
        const result = await axios.post(`${this.$store.state.apiLink}/delivery/confirm`, {
          id: this.order.systemId.trim(),
        })
        if(result.data.success) {
          this.order.dateConfirmed = true
        }

        setTimeout(() => {
          this.confirmingDelivery = false
        }, 1000);
      } catch(error) {
        console.log(error);
        this.confirmingDelivery = false
      }
    },
    async downloadOrder() {
      this.loading = true;
      const result = await axios.post(`${this.$store.state.apiLink}/order/get`, {
        id: this.$route.params.id,
      })
      if(result.data.success)
      {
        this.order = result.data.preparedOrder;
        this.checkProductsForKeywords();
        
        // this.lang = result.data.lang;
        // this.setLanguage()
        setTimeout(() => {
          this.loading = false
        }, 1000);
      };
    },
    setLanguage()
    {
      this.lang = '';
      if(window.location.origin === 'https://sofart.me') {
        this.lang = `pl`
      }
      else if(window.location.origin === "https://moeblo.net") {
        this.lang = `de`
      }
      else if(window.location.origin === "https://e-meubles.com") {
        this.lang = `fr`
      }
      else if(window.location.origin === 'https://mbmoebel.com') {
        this.lang = `it`
      }
      else if(window.location.origin === "https://inspiremefurnitures.com") {
        this.lang = `nl`
      }

      if(this.lang.toLowerCase() == "pl") {
        document.title = "Potwierdzenie dostawy zamówienia - Jan-Art"
        this.translatedData = {
          header: "Potwierdzenie dostawy zamówienia",
          headerDescription: "Kliknij w przycisk, aby potwierdzić dostawę zamówienie.",
          orderNumber: "Numer zamówienia",
          deliveryDate: "Planowana data dostawy",
          plannedDeliveryHours: "Planowane godziny dostawy",
          deliveryAddress: "Adres dostawy",
          totalProductsPrice: "Suma za wszystkie przedmioty",
          deliveryPrice: "Dostawa",
          total: "Razem",
          deliveryConfirmed: "Potwierdzono dostawę",
          deliveryNotConfirmed: "Potwierdź dostawę",
          deliveryCode: 'Kod odbioru'
        }
      }
      if(this.lang.toLowerCase() == "de") {
        document.title = "Bestätigung der Lieferung der Bestellung - Jan-Art"
        this.translatedData = {
          header: "Bestätigung der Lieferung der Bestellung",
          headerDescription: "Klicken Sie auf die Schaltfläche, um die Lieferung Ihrer Bestellung zu bestätigen",
          orderNumber: "Bestellnummer",
          deliveryDate: "Liefertermin",
          plannedDeliveryHours: "Geplante Lieferzeiten",
          deliveryAddress: "Lieferadresse",
          totalProductsPrice: "Gesamtbetrag für alle Posten",
          deliveryPrice: "Lieferung",
          total: "Total",
          deliveryConfirmed: "Lieferung bestätigt",
          deliveryNotConfirmed: "Bestätigen Sie die Lieferung",
          deliveryCode: 'Abholcode'
        }
      }
      if(this.lang.toLowerCase() == "fr") {
        document.title = "Confirmation de la livraison de l'ordre - Jan-Art"
        this.translatedData = {
          header: "Confirmation de la livraison de l'ordre",
          headerDescription: "Cliquez sur le bouton pour confirmer la livraison de votre commande",
          orderNumber: "Numéro de commande",
          deliveryDate: "Date de livraison",
          plannedDeliveryHours: "Délais de livraison prévus",
          deliveryAddress: "L'adresse de livraison",
          totalProductsPrice: "Montant total pour tous les postes",
          deliveryPrice: "Livraison",
          total: "Total",
          deliveryConfirmed: "Livraison confirmée",
          deliveryNotConfirmed: "Confirmer la livraison",
          deliveryCode: "Code de ramassage"
        }
      }
      if(this.lang.toLowerCase() == "lu") {
        document.title = "Bestätigung der Lieferung der Bestellung - Jan-Art"
        this.translatedData = {
          header: "Bestätigung der Lieferung der Bestellung",
          headerDescription: "Klicken Sie auf die Schaltfläche, um die Lieferung Ihrer Bestellung zu bestätigen",
          orderNumber: "Bestellnummer",
          deliveryDate: "Liefertermin",
          plannedDeliveryHours: "Geplante Lieferzeiten",
          deliveryAddress: "Lieferadresse",
          totalProductsPrice: "Gesamtbetrag für alle Posten",
          deliveryPrice: "Lieferung",
          total: "Total",
          deliveryConfirmed: "Lieferung bestätigt",
          deliveryNotConfirmed: "Bestätigen Sie die Lieferung",
          deliveryCode: 'Abholcode'
        }
      }
      if(this.lang.toLowerCase() == "it") {
        document.title = "Conferma della consegna dell'ordine - Jan-Art"
        this.translatedData = {
          header: "Conferma della consegna dell'ordine",
          headerDescription: "Cliccare sul pulsante per confermare la consegna dell'ordine.",
          orderNumber: "Numero d'ordine",
          deliveryDate: "Data di consegna",
          plannedDeliveryHours: "Tempi di consegna previsti",
          deliveryAddress: "Indirizzo di consegna",
          totalProductsPrice: "Totale per tutti gli articoli",
          deliveryPrice: "Consegna",
          total: "Totale",
          deliveryConfirmed: "Consegna confermata",
          deliveryNotConfirmed: "Confermare la consegna",
          deliveryCode: 'Codice di ritiro'
        }
      }
      if(this.lang.toLowerCase() == "nl") {
        document.title = "Bevestiging van levering van bestelling - Jan-Art"
        this.translatedData = {
          header: "Bevestiging van levering van bestelling",
          headerDescription: "Klik op de knop om de levering van je bestelling te bevestigen",
          orderNumber: "Ordernummer",
          deliveryDate: "Leveringsdatum",
          plannedDeliveryHours: "Geplande levertijden",
          deliveryAddress: "Afleveradres",
          totalProductsPrice: "Totaal voor alle items",
          deliveryPrice: "Levering",
          total: "Samen",
          deliveryConfirmed: "Levering bevestigd",
          deliveryNotConfirmed: "Levering bevestigen",
          deliveryCode: 'Afhaalcode'
        }
      }
      if(this.lang.toLowerCase() == "be") {
        document.title = "Bevestiging van levering van bestelling - Jan-Art"
        this.translatedData = {
          header: "Bevestiging van levering van bestelling",
          headerDescription: "Klik op de knop om de levering van je bestelling te bevestigen",
          orderNumber: "Ordernummer",
          deliveryDate: "Leveringsdatum",
          plannedDeliveryHours: "Geplande levertijden",
          deliveryAddress: "Afleveradres",
          totalProductsPrice: "Totaal voor alle items",
          deliveryPrice: "Levering",
          total: "Samen",
          deliveryConfirmed: "Levering bevestigd",
          deliveryNotConfirmed: "Levering bevestigen",
          deliveryCode: 'Afhaalcode'
        }
      }
      if(this.lang.toLowerCase() == "at") {
        document.title = "Bestätigung der Lieferung der Bestellung - Jan-Art"
        this.translatedData = {
          header: "Bestätigung der Lieferung der Bestellung",
          headerDescription: "Klicken Sie auf die Schaltfläche, um die Lieferung Ihrer Bestellung zu bestätigen",
          orderNumber: "Bestellnummer",
          deliveryDate: "Liefertermin",
          plannedDeliveryHours: "Geplante Lieferzeiten",
          deliveryAddress: "Lieferadresse",
          totalProductsPrice: "Gesamtbetrag für alle Posten",
          deliveryPrice: "Lieferung",
          total: "Total",
          deliveryConfirmed: "Lieferung bestätigt",
          deliveryNotConfirmed: "Bestätigen Sie die Lieferung",
          deliveryCode: 'Abholcode'
        }
      }
    }
  }
}
</script>

<style>
  .spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3.8px solid #4f46e5;
    animation: spinner-bulqg1 1.1199999999999999s infinite linear alternate,
          spinner-oaa3wk 2.2399999999999998s infinite linear;
  }

  @keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }

  @keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
  }
</style>